import Link from 'next/link'
import { useRef } from 'react'
import { getFileFullPath } from '../../../utils/Helpers'

function MostReadFeedItem({ date, title, category, slug, imageUrl, imageAlt }) {
  const titleRef = useRef(null)
  const imageRef = useRef(null)

  return (
    <Link href={`/${category}/-/${slug}`}>
      <a>
        <div className="flex group mb-3">
          <div className="text flex-1">
            <span className="uppercase text-gray-400 text-sm mr-1">{date}</span>
            <p
              ref={titleRef}
              className="group-hover:underline"
              style={{ fontSize: 15, lineHeight: 1.2, fontFamily: 'lato-bold' }}
            >
              {title}
            </p>
          </div>
          <div className="w-4/12 h-20 ml-3">
            <img
              ref={imageRef}
              className="group-hover:opacity-90 h-full w-full object-cover"
              src={getFileFullPath(imageUrl)}
              alt={imageAlt}
            />
          </div>
        </div>
      </a>
    </Link>
  )
}

export default MostReadFeedItem
