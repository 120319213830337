function CurrencyRate({ flagURL, country, currencyCode, value }) {
  if (!value) return null
  return (
    <div className="flex items-center justify-flex-start">
      <div className="mr-4">
        <div className="relative w-4 h-2 bg-gray-400 rounded-lg flex items-center">
          <div className="absolute w-3 h-3 bg-dnred -right-1 rounded-lg flex items-center justify-center">
            <img
              className="w-1 h-1"
              src="/trougao-white.svg"
              alt="decorative triangle"
            />
          </div>
        </div>
      </div>
      <div className="mr-3">
        <img className="w-8 h-8" src={flagURL} alt={`${country} flag`} />
      </div>
      <div
        className="flex flex-col text-gray-700 leading-5 w-16"
        style={{ fontFamily: 'lato-black' }}
      >
        <span>{currencyCode}</span>
        <span>{value.toFixed(4)}</span>
      </div>
    </div>
  )
}

export default CurrencyRate
