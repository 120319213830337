import styles from './NewsFeedItem.module.scss'
import Link from 'next/link'

type NewsFeedItem = {
  time: string
  title: string
  subtitle: string
  category: string | null
  slug: string | null
  isClickable: boolean
  isSportScheduleItem: boolean
  isLiveStream?: boolean
  liveStreamUrl?: string
}

const NewsFeedItem = ({
  time, // eslint-disable-line @typescript-eslint/no-unused-vars
  title,
  category,
  subtitle,
  slug,
  isClickable = true,
  isSportScheduleItem = false,
  isLiveStream = false,
  liveStreamUrl = '',
}: NewsFeedItem) => {
  const href = isLiveStream
    ? `/live-stream?url=${liveStreamUrl}`
    : `/${encodeURIComponent(category)}/-/${encodeURIComponent(slug)}`

  const content = (
    <div
      className={`${styles.NewsFeedItem} 
        ${isClickable ? 'cursor-pointer' : null} 
        ${isSportScheduleItem ? styles.height80 : null} 
        py-1 px-2.5 leading-6  tracking-normal`}
    >
      <p
        className={`flex overflow-ellipsis overflow-hidden mb-1.5 leading-none`}
      >
        <span className={styles.circle}>&nbsp;</span>
        {/* <span className={`${styles.time} ml-2`}>{time}</span>{' '} */}
        <span className={`${styles.title} ml-2`}>
          {isSportScheduleItem ? subtitle : title}
        </span>
      </p>
      {isSportScheduleItem && (
        <div>
          {title.split('-').map((team) => (
            <p
              key={team}
              className={`${styles.content} ml-4 overflow-ellipsis overflow-hidden`}
            >
              {team}
            </p>
          ))}
        </div>
      )}
    </div>
  )

  if (!isClickable) return content

  return (
    <Link href={href}>
      <a>{content}</a>
    </Link>
  )
}

export default NewsFeedItem
