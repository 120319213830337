import styles from './MostReadFeed.module.scss'
import { useState } from 'react'
import MostReadFeedItem from '../MostReadFeedItem/MostReadFeedItem'
import {
  Static,
  Record,
  Union,
  Array,
  String,
  Partial,
  InstanceOf,
  Null,
} from 'runtypes'
import moment from 'moment'
import { connect } from 'react-redux'
import { Category } from '../../../types'
import { useEffect } from 'react'

import NewsApi from '../../../api/news'
import { actions } from '../../../store'

const MostReadPost = Record({
  name: String,
  subtitle: String,
  slug: String,
  categories: Array(Category),
  // eslint-disable-next-line @typescript-eslint/camelcase
  featured_image: String,
}).And(
  Partial({
    /* eslint-disable @typescript-eslint/camelcase */
    published_at: Union(InstanceOf(Date), Null),
  })
)

type MostReadPost = Static<typeof MostReadPost>

const MostReadFeed = (): JSX.Element => {
  const [mostReadPosts, setMostReadPosts] = useState<MostReadPost[]>([])

  useEffect(() => {
    getMostReadFeed()
  }, [])

  const getMostReadFeed = async () => {
    try {
      const response = await NewsApi.getMostViewedPosts()
      if (response.data.data.length) {
        const posts = response.data.data.map((el) => {
          el.post.visits_count = el.visits_count
          return el.post
        })
        setMostReadPosts(posts)
      }
    } catch (e) {
      setMostReadPosts(null)
    }
  }

  if (!mostReadPosts?.length) return null

  return (
    <div
      className={`${styles.MostReadFeed} bg-white p-4 mb-5 mt-5 border border-gray-300`}
    >
      <p className={styles.title}>
        <span className="inline-block mb-4 font-bold text-white">
          NAJČITANIJE
        </span>
      </p>
      {mostReadPosts && mostReadPosts.length ? (
        <div className="-my-3">
          {mostReadPosts.map((post: MostReadPost, i: number) => {
            return (
              <MostReadFeedItem
                key={i}
                date={
                  post.published_at
                    ? moment(post.published_at).format('DD. MMM YYYY.')
                    : null
                }
                imageUrl={post.featured_image}
                imageAlt={`${post.name} thumbnail`}
                title={post.name}
                category={post.categories[0].name}
                slug={post.slug}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.mostViewedPosts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMostReadPosts: (data) => {
      dispatch({
        type: actions.SET_MOST_VIEWED_POSTS,
        payload: {
          mostViewedPosts: data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MostReadFeed)
