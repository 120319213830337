import { connect } from 'react-redux'
import {
  Array,
  Function,
  InstanceOf,
  Null,
  Partial,
  Record,
  Static,
  String,
  Union,
} from 'runtypes'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './LiveNewsFeed.module.scss'
import NewsFeedItem from '../NewsFeedItem/NewsFeedItem'
import { Category } from '../../../types'
import { useEffect, useState } from 'react'
import NewsApi from '../../../api/news'
import { actions } from '../../../store'

const LivePost = Record({
  name: String,
  subtitle: String,
  slug: String,
  categories: Array(Category),
}).And(
  Partial({
    /* eslint-disable @typescript-eslint/camelcase */
    updated_at: Union(InstanceOf(Date), Null),
    published_at: Union(InstanceOf(Date), Null),
  })
)

type LivePost = Static<typeof LivePost>

const LiveNewsFeedProps = Record({
  posts: Array(LivePost),
  setNewestPosts: Function,
})

type LiveNewsFeedProps = Static<typeof LiveNewsFeedProps>

const LiveNewsFeed = ({
  posts,
  setNewestPosts,
}: LiveNewsFeedProps): JSX.Element => {
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    if (!posts.length) {
      setPage(1)
      getLiveNewsFeed(1)
    }
  }, [posts])

  const getLiveNewsFeed = async (customPageNumber = null) => {
    if (hasMore) {
      try {
        let response
        if (customPageNumber) {
          response = await NewsApi.getNewestPosts(customPageNumber)
        } else {
          response = await NewsApi.getNewestPosts(page)
        }
        if (customPageNumber) {
          setPage(customPageNumber + 1)
        } else {
          setPage(page + 1)
        }
        if (response.data.data.length) {
          setNewestPosts(response.data.data)
        } else {
          setHasMore(false)
        }
      } catch (e) {
        setNewestPosts(null)
      }
    }
  }

  return (
    <>
      {posts && posts.length > 0 ? (
        <div className={`${styles.LiveNewsFeed} mb-5 bg-white pb-2.5`}>
          <p className={styles.title}>
            <span className="px-10 leading-8 font-bold text-white">
              NAJNOVIJE
            </span>
          </p>
          <InfiniteScroll
            dataLength={posts.length}
            next={getLiveNewsFeed}
            hasMore={hasMore}
            loader={null}
            className="sport-schedule-scroll mt-1.5"
            height={330}
          >
            {posts.map((post: LivePost, i: number) => {
              return (
                <NewsFeedItem
                  key={i}
                  time={
                    post.updated_at
                      ? moment(post.updated_at).format('HH:mm')
                      : moment(post.published_at).format('HH:mm')
                  }
                  subtitle={post.subtitle}
                  title={post.name}
                  category={post.categories[0].name}
                  slug={post.slug}
                  isClickable={true}
                  isSportScheduleItem={false}
                />
              )
            })}
          </InfiniteScroll>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    posts: state.newestPosts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNewestPosts: (data) => {
      dispatch({
        type: actions.SET_NEWEST_POSTS,
        payload: {
          newestPosts: data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveNewsFeed)
