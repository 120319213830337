import styles from './Kairon.module.scss'
import { getFileFullPath } from '../../../utils/Helpers'
import FitText from '@kennethormandy/react-fittext'
import { useEffect, useState } from 'react'
import useInterval from '../../../lib/useInterval'

interface Kairon {
  url: string
  name: string
}

const Kairon = ({ url, name }: Kairon) => {
  const [chironId, setChironId] = useState(0)

  function setRandomChironId() {
    setChironId(Math.floor(Math.random() * 1000))
  }

  useEffect(setRandomChironId, [])

  useInterval(setRandomChironId, 5000)

  return (
    <div className={`${styles.Kairon} w-full`}>
      <img
        src={getFileFullPath(url)}
        alt="kairon image"
        id={'kairon-image-' + chironId}
      />
      <div className={`${styles.KaironText}`}>
        <FitText compressor={3} parent={'kairon-image-' + chironId}>
          {name}
        </FitText>
      </div>
    </div>
  )
}

export default Kairon
