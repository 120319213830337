import { connect } from 'react-redux'
import PostSizeS from '../PostSizeS/PostSizeS'
import { Record, String, Static, Function } from 'runtypes'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewsApi from '../../../api/news'
import { actions } from '../../../store'
import { SearchResponse } from '../../../types/posts'
import { getFileFullPath } from '../../../utils/Helpers'

const SearchResultsProps = Record({
  searchResultsPagination: SearchResponse,
  searchQuery: String,
  setSearchResults: Function,
})

type SearchResultsProps = Static<typeof SearchResultsProps>

const SearchResults = ({
  searchResultsPagination,
  searchQuery,
  setSearchResults,
}: SearchResultsProps) => {
  const filteredCategories = searchResultsPagination.data.filter(
    (searchResult) => searchResult.categories.length
  )

  const fetchMoreResults = async () => {
    try {
      const response = await NewsApi.searchByQuery(
        searchQuery,
        searchResultsPagination.current_page + 1
      )
      setSearchResults(response.data)
    } catch (e) {
      setSearchResults(null)
    }
  }

  return (
    <div>
      <InfiniteScroll
        dataLength={searchResultsPagination.data.length} //This is important field to render the next data
        next={fetchMoreResults}
        hasMore={
          searchResultsPagination.data.length < searchResultsPagination.total
        }
        loader={null}
        className="overflow-hidden"
      >
        {filteredCategories.map((searchResult) => {
          return (
            <PostSizeS
              key={searchResult.id}
              post={{
                title: searchResult.name,
                subtitle: searchResult.subtitle,
                url: getFileFullPath(searchResult.featured_image),
                slug: searchResult.slug,
                categoryId: searchResult.categories[0].id,
              }}
              category={{
                color: searchResult.categories[0].color,
                id: searchResult.categories[0].id,
                name: searchResult.categories[0].name,
              }}
              subCategory={null}
            />
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchResultsPagination: state.searchResultsPagination,
  searchQuery: state.searchQuery,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchResults: (results) => {
      dispatch({
        type: actions.SET_SEARCH_RESULTS,
        payload: {
          results,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
