import Link from 'next/link'
import {
  Record,
  Static,
  Boolean,
  Partial,
  Union,
  Null,
  Literal,
} from 'runtypes'

import styles from './PostSizeS.module.scss'
import { Category, Post } from '../../../types'
import { formatUrl, isLinkForVideo } from '../../../utils/Helpers'
import Kairon from '../Kairon/Kairon'

export const PostSizeSProps = Record({
  post: Post,
  category: Union(Category, Null),
  subCategory: Union(Category, Null),
}).And(
  Partial({
    showTopMargin: Boolean,
    imagePosition: Union(Literal('left'), Literal('right')),
  })
)

export type PostSizeSProps = Static<typeof PostSizeSProps>

const PostSizeS = ({
  post,
  showTopMargin = true,
  category,
  subCategory,
  imagePosition = 'left',
}: PostSizeSProps) => {
  PostSizeSProps.check({ post, category, subCategory, imagePosition })

  const url = formatUrl(
    `/${encodeURIComponent(category?.name)}/${encodeURIComponent(
      subCategory?.name ?? '-'
    )}/${post.slug}`
  )

  if (!category) return null
  return (
    <Link href={url}>
      <a>
        <div
          className={`${styles.postSizeS}  ${
            showTopMargin ? 'mt-2.5' : null
          }  cursor-pointer relative bg-white`}
        >
          {post.chiron_url ? (
            <Kairon url={post.chiron_url} name={post.chiron_name} />
          ) : null}
          <div
            className={`${
              imagePosition == 'right' ? 'flex-row-reverse' : null
            } flex ${styles.spaceBetween}`}
          >
            <div>
              {isLinkForVideo(post.url) ? (
                <video autoPlay muted loop src={post.url}>
                  Your browser does not support videos.
                </video>
              ) : (
                <div
                  className={`${styles.image} ${
                    imagePosition === 'left' ? 'ml-0 mr-2.5' : 'ml-2.5 mr-0'
                  } my-2.5 lg:mx-2.5`}
                  style={{ backgroundImage: `url('${post.url}')` }}
                />
              )}
            </div>
            <div className={`${styles.text} md:p-2.5 my-2.5`}>
              <div className="flex items-start">
                <div className="w-full flex items-start justify-between">
                  {post.hasVideo && (
                    <img
                      alt="yt-icon"
                      src="/video-icon.svg"
                      width={32}
                      height={32}
                      className={`pt-1 ${styles.ytIcon}`}
                    />
                  )}
                </div>
              </div>
              <div className={styles.titleWrapper}>
                <h3 className={`${styles.title} tracking-normal leading-6`}>
                  <span className={styles.innerText}>
                    <div className={styles.redTriangleContainer}>
                      <img src="/trougao.svg" className={styles.redTriangle} />
                    </div>

                    {post.isNew ? (
                      <span className={`${styles.novo} pr-2`}>NOVO:</span>
                    ) : null}
                    {post.title}
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default PostSizeS
