import React from 'react'
import styles from './Content.module.scss'

const Content = (props) => {
  if (props.bgColor) {
    return (
      <div
        className={`${styles.content} px-0 sm:px-5 w-full`}
        style={{ backgroundColor: props.bgColor }}
      >
        {props.children}
      </div>
    )
  }
  return (
    <div className={`${styles.content} px-0 sm:px-5`}>{props.children}</div>
  )
}

export default Content
