import styles from './RightSidePanel.module.scss'
import SideAdBanner from '../../Advertisement/SideAdBanner/SideAdBanner'
import LiveNewsFeed from '../LiveNewsFeed/LiveNewsFeed'
import MostReadFeed from '../MostReadFeed/MostReadFeed'
import { BannerPosition } from '../../../types/banner'
import CategoryModule from '../CategoryModule/CategoryModule'
// import ImageLink from '../ImageLink/ImageLink'
import CurrencyRates from '../CurrencyRates/CurrencyRates'
import BestFromMNE from '../BestFromMNE/BestFromMNE'
import SportFeed from '../SportFeed/SportFeed'

const RightSidePanel = () => {
  return (
    <div className="flex">
      <div className={`${styles.RightSidePanel}`}>
        <LiveNewsFeed />
        <SideAdBanner position={BannerPosition.THREE} />
        {/* <ImageLink href="https://dnovine.me" imageUrl="/dnovine.jpg" /> */}
        <BestFromMNE />
        <SideAdBanner position={BannerPosition.FOUR} />
        <SideAdBanner position={BannerPosition.NINE} />
        <CategoryModule
          categoryId={47}
          categoryPath="život-i-stil"
          categoryName="Život i stil"
        />
        <SideAdBanner position={BannerPosition.TEN} />
        <SportFeed />
        <SideAdBanner position={BannerPosition.ELEVEN} />
        <CategoryModule
          categoryId={48}
          categoryPath="kalendar-zdravlja"
          categoryName="Kalendar zdravlja"
        />
        <CurrencyRates />
        {/*<CovidInfo />*/}
        <MostReadFeed />
        <CategoryModule
          categoryId={49}
          categoryPath="crnom-gorom"
          categoryName="Crnom Gorom"
        />
        <CategoryModule
          categoryId={50}
          categoryPath="glavni-grad"
          categoryName="Glavni grad"
        />
        <CategoryModule
          categoryId={51}
          categoryPath="dijaspora"
          categoryName="Dijaspora"
        />
      </div>
    </div>
  )
}

export default RightSidePanel
