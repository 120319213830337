import moment from 'moment'
import styles from './SportFeedItem.module.scss'

function SportFeedItem({ match, isLiveStream = false }) {
  return (
    <div className="py-4 pl-5">
      <div className={`${styles.matchTitle} flex mb-1`}>
        <span className="flex-1 uppercase text-dnred">{match.game}</span>
        {isLiveStream && (
          <a href={match.link} target="_blank" rel="noreferrer">
            <span className="px-3 py-0.5 bg-dnred rounded-3xl text-xs text-white">
              Link
            </span>
          </a>
        )}
      </div>
      <div className={`${styles.matchInfo} flex text-xs text-yellow-500`}>
        <span>{match.sport}</span>
        <span>{match.competition || match.league}</span>
        {!isLiveStream && <span>{match.channel}</span>}
        <span>
          {isLiveStream ? moment(match.time).format('HH:MM') : match.time}
        </span>
      </div>
    </div>
  )
}

export default SportFeedItem
