import styles from './BestFromMNE.module.scss'
import { useState } from 'react'
import BestFromMNEItem from '../BestFromMNEItem/BestFromMNEItem'
import {
  Static,
  Record,
  Union,
  String,
  Partial,
  InstanceOf,
  Null,
} from 'runtypes'
// import { connect } from 'react-redux'
import { useEffect } from 'react'
import Link from 'next/link'

import NewsApi from '../../../api/news'
import { useRouter } from 'next/router'
// import { actions } from '../../../store'

const BestFromMNEPost = Record({
  name: String,
  interlocutor: String,
  slug: String,
  // eslint-disable-next-line @typescript-eslint/camelcase
  featured_image: String,
}).And(
  Partial({
    /* eslint-disable @typescript-eslint/camelcase */
    published_at: Union(InstanceOf(Date), Null),
  })
)

type BestFromMNEPost = Static<typeof BestFromMNEPost>

const MostReadFeed = (): JSX.Element => {
  const [bestFromMNEItems, setBestFromMNEItems] = useState<BestFromMNEPost[]>(
    []
  )
  const router = useRouter()

  useEffect(() => {
    async function getNewsItem() {
      const results = await NewsApi.getNewsByCategoryId(46, 1, 5)
      const newsItemData = results.data.data
      setBestFromMNEItems(newsItemData)
    }
    getNewsItem()
  }, [])

  if (router.asPath === '/najbolje-iz-crne-gore' || !bestFromMNEItems?.length)
    return null

  return (
    <div
      className={`${styles.bestFromMNE} bg-white p-4 mb-5 mt-5 border border-gray-300`}
    >
      <p className={styles.title}>
        <Link href="/najbolje-iz-crne-gore">
          <span className="inline-block mb-6 font-bold text-white cursor-pointer">
            NAJBOLJE IZ CRNE GORE
          </span>
        </Link>
      </p>
      {bestFromMNEItems && bestFromMNEItems.length ? (
        <div className="-my-3">
          {bestFromMNEItems.map((post: BestFromMNEPost, i: number) => {
            return (
              <BestFromMNEItem
                key={i}
                guestName={post.interlocutor || ''}
                imageUrl={post.featured_image}
                imageAlt={`${post.name} thumbnail`}
                title={post.name}
                slug={post.slug}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

// const mapStateToProps = (state) => {
//   return {
//     posts: state.mostViewedPosts,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setMostReadPosts: (data) => {
//       dispatch({
//         type: actions.SET_MOST_VIEWED_POSTS,
//         payload: {
//           mostViewedPosts: data,
//         },
//       })
//     },
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(MostReadFeed)

export default MostReadFeed
