import styles from './SideVerticalAdvertisementBar.module.scss'
import { Banner, BannerPosition } from '../../../types/banner'
import { connect } from 'react-redux'
import { getFileFullPath, getRandomArrayItem } from '../../../utils/Helpers'
import { useEffect, useState } from 'react'
import config from '../../../config.json'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'

interface SideVerticalAdvertisementBar {
  banners: Banner[]
}

const SideVerticalAdvertisementBar = ({
  banners,
}: SideVerticalAdvertisementBar) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => setScreenWidth(window.innerWidth), [])

  const randomBanner = getRandomArrayItem(
    banners.filter((banner) => banner.position === BannerPosition.VERTICAL_LEFT)
  )

  if (screenWidth < 1360) return null

  return (
    /* eslint-disable */
    <div className={styles.sideVerticalAdvertisementBarWrapper}>
      {randomBanner ? (
        <a
          target="_blank"
          href={randomBanner.banner_url}
          onClick={() => {
            axios
              .post(`${config.apiBaseUrl}banner-clicked-api`, {
                id: randomBanner.id,
                position: 2,
                // eslint-disable-next-line
                from_url: window.location.href,
                // eslint-disable-next-line
                to_url: randomBanner.banner_url,
              })
              .catch((e) => Sentry.captureException(e))
          }}
        >
          {randomBanner.video ? (
            <video
              className={`${styles.sideVerticalAdvertisementBar} object-top`}
              muted
              autoPlay
              loop
              poster={getFileFullPath(randomBanner.banner_image)}
            >
              <source
                src={getFileFullPath(randomBanner.video)}
                type="video/mp4"
              />
            </video>
          ) : (
            <div
              className={`${styles.sideVerticalAdvertisementBar} styles.sideVerticalAdvertisementBar`}
              style={{
                backgroundImage: `url(${getFileFullPath(
                  randomBanner.banner_image
                )})`,
              }}
            />
          )}
        </a>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  }
}

export default connect(mapStateToProps)(SideVerticalAdvertisementBar)
