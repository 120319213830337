import { connect } from 'react-redux'

import styles from './SideAdBanner.module.scss'
import { Banner, BannerPosition } from '../../../types/banner'
import { getFileFullPath, getRandomArrayItem } from '../../../utils/Helpers'
import axios from 'axios'
import config from '../../../config.json'
import * as Sentry from '@sentry/nextjs'
interface SideAdBanner {
  banners: Banner[]
  position: BannerPosition
}

const SideAdBanner = ({ banners, position }: SideAdBanner) => {
  const randomBanner = getRandomArrayItem(
    banners.filter((banner) => banner.position === position)
  )
  if (!randomBanner) return null
  return (
    <div className="mb-5">
      <p className={`${styles.advertisement} h-5 flex items-center`}>
        ADVERTISEMENT
      </p>
      {/*eslint-disable-next-line*/}
      <a
        onClick={() => {
          axios
            .post(`${config.apiBaseUrl}banner-clicked-api`, {
              id: randomBanner.id,
              position: position,
              // eslint-disable-next-line
              from_url: window.location.href,
              // eslint-disable-next-line
              to_url: randomBanner.banner_url,
            })
            .catch((e) => Sentry.captureException(e))
        }}
        target="_blank"
        href={randomBanner.banner_url}
      >
        <img
          src={getFileFullPath(randomBanner.banner_image)}
          className={`${styles.SideAdBanner}`}
        />
      </a>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  }
}

export default connect(mapStateToProps)(SideAdBanner)
