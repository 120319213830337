import config from '../../../config.json'
import { useEffect, useState } from 'react'
import CurrencyRate from '../CurrencyRate/CurrencyRate'
import classes from './CurrencyRates.module.scss'

function CurrencyRates() {
  const [currencies, setCurrencies] = useState<{
    usd?: number
    rub?: number
    gbp?: number
    hrk?: number
    chf?: number
    aud?: number
  }>({})
  useEffect(() => {
    async function fetchCurrencies() {
      const response = await fetch(`${config.apiBaseUrl}exchange-rates-api`)
      const data = await response.json()
      setCurrencies(data)
    }
    fetchCurrencies()
  }, [])

  return (
    <div className="bg-white relative border-b-4 border-dnred mb-5">
      <div
        className={`${classes.currencyHeader} absolute w-full bg-dnred text-white uppercase text-sm px-4 py-3`}
        style={{ fontFamily: 'lato-black' }}
      >
        Kursna lista
      </div>
      <div className="px-4 pt-16 pb-6 grid grid-cols-2 justify-items-center gap-y-4">
        <CurrencyRate
          country="US"
          currencyCode="USD"
          flagURL="/country-flags/us.svg"
          value={currencies.usd}
        />
        <CurrencyRate
          country="RU"
          currencyCode="RUB"
          flagURL="/country-flags/ru.svg"
          value={currencies.rub}
        />
        <CurrencyRate
          country="GB"
          currencyCode="GBP"
          flagURL="/country-flags/gb.svg"
          value={currencies.gbp}
        />
        <CurrencyRate
          country="HR"
          currencyCode="HRK"
          flagURL="/country-flags/hr.svg"
          value={currencies.hrk}
        />
        <CurrencyRate
          country="CH"
          currencyCode="CHF"
          flagURL="/country-flags/ch.svg"
          value={currencies.chf}
        />
        <CurrencyRate
          country="AUS"
          currencyCode="AUD"
          flagURL="/country-flags/au.svg"
          value={currencies.aud}
        />
      </div>
    </div>
  )
}

export default CurrencyRates
