import { Number, Partial, Record, Static, String } from 'runtypes'

/* eslint-disable @typescript-eslint/camelcase */
export const Banner = Record({
  id: Number,
  banner_name: String,
  position: String,
  banner_url: String,
  banner_image: String,
}).And(
  Partial({
    video: String,
    video_mobile: String,
    banner_image_mobile: String,
  })
)
export type Banner = Static<typeof Banner>

export enum BannerPosition {
  TOP = '1',
  VERTICAL_LEFT = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ELEVEN = '11',
}
