import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import newsApi from '../../../api/news'
import {
  getFileFullPath,
  getYoutubeThumbnailImageUrl,
  isLinkForVideo,
} from '../../../utils/Helpers'
import { useRouter } from 'next/router'
import { connect } from 'react-redux'
import { actions } from '../../../store'

type CategoryModuleProps = {
  categoryId: number
  categoryPath: string
  categoryName: string
  showVideoModal?: () => void
  setVideo?: (videoUrl: string) => void
}

function CategoryModule({
  categoryId,
  categoryPath,
  categoryName,
  showVideoModal,
  setVideo,
}: CategoryModuleProps) {
  const router = useRouter()
  const imageRef = useRef(null)
  const titleRef = useRef(null)
  const [newsItem, setNewsItem] = useState<{
    featured_image?: string
    featured_legend?: string
    name?: string
    slug?: string
    has_video?: 0 | 1
    video?: string
  }>({})
  useEffect(() => {
    async function getNewsItem() {
      const results = await newsApi.getNewsByCategoryId(categoryId, 1, 1)
      const newsItemData = results.data.data[0]
      setNewsItem(newsItemData)
    }
    getNewsItem()
  }, [])

  const handleMouseOver = () => {
    imageRef.current.classList.remove('group-hover:opacity-90')
    titleRef.current.classList.remove('group-hover:underline')
  }

  const handleMouseOut = () => {
    imageRef.current.classList.add('group-hover:opacity-90')
    titleRef.current.classList.add('group-hover:underline')
  }

  // IF IT'S A VIDEO POST THIS
  if (newsItem?.has_video && newsItem?.video) {
    return (
      <div
        onClick={() => {
          setVideo(newsItem?.video)
          showVideoModal()
        }}
        className="w-full border border-gray-200 bg-white group mb-5 cursor-pointer"
      >
        <div className="w-full h-48 relative">
          {isLinkForVideo(newsItem?.featured_image) ? (
            <video
              autoPlay
              muted
              loop
              src={getFileFullPath(newsItem?.featured_image)}
            >
              Your browser does not support videos.
            </video>
          ) : (
            <img
              ref={imageRef}
              className="w-full h-full object-cover object-center group-hover:opacity-90"
              src={
                getFileFullPath(newsItem?.featured_image) ||
                getYoutubeThumbnailImageUrl(newsItem?.video)
              }
              alt={newsItem?.featured_legend}
            />
          )}
          <img
            className="absolute bottom-4 left-4 w-10 h-auto"
            src="/video-icon.svg"
            alt="video icon"
          />
        </div>

        <div className="flex flex-col px-4 pt-3 pb-4">
          <Link href={`/${categoryPath}`}>
            <p
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={(e) => e.stopPropagation()}
              className="inline-block uppercase text-sm text-dnred font-black tracking-wide mb-1 hover:underline"
              style={{ fontFamily: 'lato-black', width: 'fit-content' }}
            >
              {categoryName}
            </p>
          </Link>

          <div>
            <img
              src="/trougao.svg"
              className="h-2 mr-2 float-left mt-1.5"
              alt="decorative triangle"
            />
            <h3
              ref={titleRef}
              className={`font-bold text-lg leading-5 group-hover:underline`}
              style={{ fontFamily: 'lato-bold' }}
            >
              {newsItem?.name}
            </h3>
          </div>
        </div>
      </div>
    )
  }

  // IF IT'S A REGULAR POST
  return router.asPath !== `/${categoryPath}` && newsItem ? (
    <Link href={`/${categoryPath}/-/${newsItem.slug}`}>
      <div className="w-full border border-gray-200 bg-white group mb-5 cursor-pointer">
        <div className="w-full h-48">
          {isLinkForVideo(newsItem?.featured_image) ? (
            <video
              autoPlay
              muted
              loop
              src={getFileFullPath(newsItem.featured_image)}
            >
              Your browser does not support videos.
            </video>
          ) : (
            <img
              ref={imageRef}
              className="w-full h-full object-cover object-center group-hover:opacity-90"
              src={getFileFullPath(newsItem.featured_image)}
              alt={newsItem.featured_legend}
            />
          )}
        </div>

        <div className="flex flex-col px-4 pt-3 pb-4">
          <Link href={`/${categoryPath}`}>
            <p
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={(e) => e.stopPropagation()}
              className="inline-block uppercase text-sm text-dnred font-black tracking-wide mb-1 hover:underline"
              style={{ fontFamily: 'lato-black', width: 'fit-content' }}
            >
              {categoryName}
            </p>
          </Link>

          <div>
            <img
              src="/trougao.svg"
              className="h-2 mr-2 float-left mt-1.5"
              alt="decorative triangle"
            />
            <h3
              ref={titleRef}
              className={`font-bold text-lg leading-5 group-hover:underline`}
              style={{ fontFamily: 'lato-bold' }}
            >
              {newsItem.name}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  ) : null
}

// export default CategoryModule

const mapStateToProps = (state) => ({
  displayVideoModal: state.displayVideoModal,
  videoUrl: state.videoUrl,
})

const mapDispatchToProps = (dispatch) => {
  return {
    showVideoModal: () => {
      dispatch({
        type: actions.SHOW_VIDEO_MODAL,
      })
    },
    setVideo: (videoUrl) => {
      dispatch({
        type: actions.SET_VIDEO,
        payload: videoUrl,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModule)
