import { connect } from 'react-redux'
import { Record, String, Static, Array, Function } from 'runtypes'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { actions } from '../../../store'
import NewsApi from '../../../api/news'
import styles from './SportFeed.module.scss'
import SportFeedItem from '../SportFeedItem/SportFeedItem'
import LiveStreamsAPI from '../../../api/live-streams'

const SportScheduleMatch = Record({
  time: String,
  sport: String,
  channel: String,
  competition: String,
  game: String,
})

interface LiveStream {
  id: number
  title: string
  sport: string
  league: string
  game: string
  link: string
  time: string
  sort_order: number
}

type SportScheduleMatch = Static<typeof SportScheduleMatch>

const SportScheduleFeedProps = Record({
  sportSchedule: Array(SportScheduleMatch),
  setSportSchedule: Function,
})

type SportScheduleFeedProps = Static<typeof SportScheduleFeedProps>

function SportFeed({
  sportSchedule,
  setSportSchedule,
}: SportScheduleFeedProps): JSX.Element {
  const [tab, setTab] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [streams, setStreams] = useState<LiveStream[]>([])

  useEffect(() => {
    getSportScheduleFeed()
    fetchLiveStreams()
  }, [])

  const getSportScheduleFeed = async () => {
    if (hasMore) {
      try {
        const sportScheduleResponse = await NewsApi.getSportSchedule(page)
        setPage(page + 1)
        if (sportScheduleResponse.data.data.length) {
          setSportSchedule(sportScheduleResponse.data.data)
        } else {
          setHasMore(false)
        }
      } catch (e) {
        setSportSchedule([])
      }
    }
  }

  const fetchLiveStreams = async () => {
    const response = await LiveStreamsAPI.getStreams()
    setStreams(response.data?.data ?? null)
  }

  if (!sportSchedule.length && !streams.length) return null

  return (
    <div
      className={`${styles.sportFeed} my-5 border  border-gray-200 bg-white text-gray-700`}
    >
      <p className={`${styles.title} flex`}>
        <span
          onClick={() => (tab ? setTab(0) : undefined)}
          className={`${
            !tab ? styles.active : ''
          } leading-8 text-center flex-1 cursor-pointer`}
        >
          SPORTSKI EKRAN
        </span>
        {streams.length ? (
          <span
            onClick={() => (!tab ? setTab(1) : undefined)}
            className={`${
              tab ? styles.active : ''
            } leading-8 text-center flex-1 cursor-pointer`}
          >
            UŽIVO PRENOSI
          </span>
        ) : null}
      </p>
      {!tab ? (
        <InfiniteScroll
          dataLength={sportSchedule.length}
          next={getSportScheduleFeed}
          hasMore={hasMore}
          loader={null}
          className="sport-schedule-scroll"
          height={sportSchedule.length > 5 ? 330 : undefined}
        >
          {sportSchedule.map((match: SportScheduleMatch, i: number) => {
            return <SportFeedItem key={i} match={match} isLiveStream={false} />
          })}
        </InfiniteScroll>
      ) : (
        <InfiniteScroll
          dataLength={streams.length}
          next={fetchLiveStreams}
          hasMore={hasMore}
          loader={null}
          className="sport-schedule-scroll"
          height={streams.length > 5 ? 330 : undefined}
        >
          {streams.map((match: LiveStream, i: number) => {
            return <SportFeedItem key={i} match={match} isLiveStream={true} />
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sportSchedule: state.sportSchedule,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSportSchedule: (data) => {
      dispatch({
        type: actions.SET_SPORT_SCHEDULE,
        payload: {
          sportSchedule: data,
        },
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SportFeed)
