import { connect } from 'react-redux'

import styles from './FullWidthAdBanner.module.scss'
import { Banner, BannerPosition } from '../../../types/banner'
import { getFileFullPath, getRandomArrayItem } from '../../../utils/Helpers'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import config from '../../../config.json'
import axios from 'axios'

export interface FullWidthAdBanner {
  banners: Banner[]
}

const FullWidthAdBanner = ({ banners }: FullWidthAdBanner) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => setScreenWidth(window.innerWidth), [])

  const randomBanner = getRandomArrayItem(
    banners.filter((banner) => banner.position === BannerPosition.TOP)
  )

  let bannerImageUrl = null
  let bannerVideoUrl = null
  if (randomBanner && screenWidth > 0) {
    bannerImageUrl =
      randomBanner.banner_image_mobile && screenWidth < 756
        ? randomBanner.banner_image_mobile
        : randomBanner.banner_image
  }

  if (randomBanner && screenWidth > 0) {
    bannerVideoUrl =
      randomBanner.video_mobile && screenWidth < 756
        ? randomBanner.video_mobile
        : randomBanner.video
  }

  useEffect(() => {
    const video = document.querySelectorAll('video')
    const currentVideo = video && video[0]

    if (!currentVideo) return
    currentVideo.autoplay = true
    currentVideo.load()
  }, [bannerVideoUrl])

  return (
    <div className={styles.wrapper}>
      {randomBanner ? (
        <>
          <p className={`${styles.advertisement} h-5 flex items-center`}>
            ADVERTISEMENT
          </p>
          {/*eslint-disable-next-line*/}
          <a
            target="_blank"
            href={randomBanner.banner_url}
            onClick={() => {
              axios
                .post(`${config.apiBaseUrl}banner-clicked-api`, {
                  id: randomBanner.id,
                  position: 1,
                  // eslint-disable-next-line
                  from_url: window.location.href,
                  // eslint-disable-next-line
                  to_url: randomBanner.banner_url,
                })
                .catch((e) => Sentry.captureException(e))
            }}
          >
            <div className={`${styles.fullWidthAdBanner} ml-auto mr-auto`}>
              {randomBanner.video ? (
                <video muted poster={getFileFullPath(bannerImageUrl)}>
                  <source
                    src={getFileFullPath(bannerVideoUrl)}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img src={getFileFullPath(bannerImageUrl)} />
              )}
            </div>
          </a>
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    banners: state.banners,
  }
}

export default connect(mapStateToProps)(FullWidthAdBanner)
