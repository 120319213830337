import React from 'react'

import styles from '../EmptySearchResults/EmptySearchResults.module.scss'

const EmptySearchResults = () => {
  return (
    <p className={`w-full mt-32 text-center ${styles.EmptySearchResults} `}>
      Nema rezultata pretrage
    </p>
  )
}

export default EmptySearchResults
