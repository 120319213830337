import Link from 'next/link'
import { getFileFullPath, isLinkForVideo } from '../../../utils/Helpers'

function MostReadFeedItem({ title, slug, imageUrl, imageAlt, guestName }) {
  return (
    <Link href={`/najbolje-iz-crne-gore/-/${slug}`}>
      <a>
        <div className="flex group mb-3">
          <div className="w-3/12 h-20 mr-3">
            <div>
              {isLinkForVideo(imageUrl) ? (
                <video autoPlay muted loop src={getFileFullPath(imageUrl)}>
                  Your browser does not support videos.
                </video>
              ) : (
                <img
                  className="group-hover:opacity-90 h-full w-full object-cover"
                  src={getFileFullPath(imageUrl)}
                  alt={imageAlt}
                />
              )}
            </div>
          </div>
          <div className="text flex-1 leading-3">
            <span
              className="text-dnred text-xs uppercase"
              style={{ fontFamily: 'lato-bold' }}
            >
              {guestName}
            </span>
            <p
              className="group-hover:underline"
              style={{ fontSize: 15, lineHeight: 1.2, fontFamily: 'lato-bold' }}
            >
              <img
                src="/trougao.svg"
                className="h-2 mr-2 float-left mt-1.5"
                alt="decorative triangle"
              />
              {title}
            </p>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default MostReadFeedItem
