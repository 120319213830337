import { Array, Number, Record, Static } from 'runtypes'
import { PostFromServer } from './news'
import { CategoryApiResponse } from './category'

export const SearchResponse = Record({
  data: Array(
    PostFromServer.And(
      Record({
        categories: Array(CategoryApiResponse),
      })
    )
  ),
  /* eslint-disable @typescript-eslint/camelcase */
  current_page: Number,
  from: Number,
  lastPage: Number,
  total: Number,
})

export type SearchResponse = Static<typeof SearchResponse>
